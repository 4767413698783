.iframe_stock {
	width: 450px;
	height: 450px;
	margin: 0 auto;
	display: block;
	position: relative;
    z-index: 1;


	@media all and (max-width: $bp-xs) {
		width: 260px;
		height: 260px;
	}
}

.m-stock {
	
	// width: 450px;
	width: 100%;
	height: 450px;
	background: #fff;
	margin: 0 auto;
	border-radius: 50%;
	text-align: center;
	position: relative;
	overflow: hidden;
	
	@media all and (max-width: 260px) {
		height: 260px;
	}

	&:before {
	    content: "";
	    height: 40px;
	    width: 2px;
	    margin-top: 450px;
	    border-right: 2px dashed #b39acd;
	    z-index: -1;
	    position: absolute;
	    left: 50%;
		transform: translateX(-50%);

		@media all and (max-width: 260px) {
			display: none;
		}
	}

	&__top-part {
		width: 135px;
		padding: 0 20px;
		display: inline-block;
		margin: 60 auto 0;
		color: $light-purple;
		font-size: 30px;
		margin-top: 60px;

		@media all and (max-width: 260px) {
			font-size: 17px;
			width: 90px;
			margin-top: 35px;
			// padding: 0 10px;
		}	

		@include typo-sans;

		&:first-child {
			border-right: 2px dashed $purple-line;
   			margin-right: -10px;
   		}
	}
	&__amount {
		@include typo-book;
		display: block;
	}
	.kronor {
		font-size: 130px;

		@media all and (max-width: 260px) {
			font-size: 75px;
		}	
	}
	&__cents, 
	&__currency {
		font-size: 58px;

		@media all and (max-width: 260px) {
			font-size: 35px;
		}
	}

	&__percentage {
		// @include typo-regular;
		font-size: 35px;
		border-bottom: 8px solid $purple;
		display: inline-block;
		margin: 0 auto 10px;
		font-weight: bold;

		@media all and (max-width: 260px) {
			font-size: 20px;
			border-bottom: 4px solid $purple;

		}
	}
	&__time {
		@include typo-book;

		display: block;
		font-size: 30px;

		@media all and (max-width: 260px) {
			font-size: 17px;
			padding-top: 10px;
		}	
	}
	p {
		margin-bottom: 0;
	}
	
}

#aktie {
	@media all and (max-width: 260px) {
		padding-bottom: 30px;

		.o-transcription {
			top: 0;
		}
	}
		
}