.o-container {
  margin: 0 auto;
  max-width: $container-max-width;
  @include horizontal-default-gutter(2);
}

.o-row {
    @include clearfix();
}

.o-col {
    width: 100%;
    float: left;
    @include horizontal-default-gutter(4);

    @for $i from 1 through $grid-cols {
        &--#{$i} {
            width: (100% / $grid-cols) * $i;
            width: calc((100% / #{$grid-cols}) * #{$i});
        }

        &--offset-#{$i} {
            margin-left: (100% / $grid-cols) * $i;
            margin-left: calc((100% / #{$grid-cols}) * #{$i});
        }

        &--offset-0 {
            margin-left: 0;
        }
    }

    @include breakpoint(medium) {
        @for $i from 1 through $grid-cols {
            &--md-#{$i} {
                width: (100% / $grid-cols) * $i;
                width: calc(((100% / #{$grid-cols}) * #{$i}));
            }

            &--md-offset-#{$i} {
                margin-left: (100% / $grid-cols) * $i;
                margin-left: calc((100% / #{$grid-cols}) * #{$i});
            }

            &--md-offset-0 {
                margin-left: 0;
            }
        }
    }

    @include breakpoint(small) {
        @for $i from 1 through $grid-cols {
            &--sm-#{$i} {
                width: (100% / $grid-cols) * $i;
                width: calc(((100% / #{$grid-cols}) * #{$i}));
            }

            &--sm-offset-#{$i} {
                margin-left: (100% / $grid-cols) * $i;
                margin-left: calc((100% / #{$grid-cols}) * #{$i});
            }

            &--sm-offset-0 {
                margin-left: 0;
            }
        }
    }

    @include breakpoint(xsmall) {
        @for $i from 1 through $grid-cols {
            &--xs-#{$i} {
                width: (100% / $grid-cols) * $i;
                width: calc(((100% / #{$grid-cols}) * #{$i}));
            }

            &--xs-offset-#{$i} {
                margin-left: (100% / $grid-cols) * $i;
                margin-left: calc((100% / #{$grid-cols}) * #{$i});
            }

            &--xs-offset-0 {
                margin-left: 0;
            }

        }
    }
}

