* {
	box-sizing: border-box;
}

html {
    font-family: $base-font-family;
}

body {
	padding-top: 65px;
	background: $off-white;
}

.no-scroll {
	height: 100%; 
	overflow: hidden;
}



