html.modal-open {
 	//height: 100%;
 	overflow: hidden;
 	position: relative;
}

body.modal-open {
    //height: 100%;
	overflow: hidden;
	position: relative;

	&.ios{
 		height: 100%;
 		position: fixed;
 	}

	&.hasScrollbar {
		padding-right: 15px;
	}
}

// .wrapper.modal-open {
//     //height: 100%;
// 	overflow: hidden;
// 	position: relative;

// }

.modal {
	width: 100%;
	height: 100%;
	background: #000;
	left: 0;
	position: fixed;
    z-index: 2000;

	&.open {
		-webkit-overflow-scrolling: touch;
	}
}

.modal {
	//visibility: hidden;
	display: none;

	&.open {
		//visibility: visible;
		display: block;
		iframe {
			position: relative;
    		top: 50%;
    		transform: translateY(-50%);
		}
	}

}

.modalOverlay {
	visibility: hidden;
	display: none;
	background: rgba(255, 255, 255, 0);
	position: fixed;
	top: 0;
	left: 0;

	width: 100%;
	height: 100%;
	transition: background 0.5s ease;
	overflow: scroll;
	
	&.open {
		visibility: visible;
		display: block;	
		z-index: 9999999;
		background: rgb(255, 255, 255);
		background: rgba(255, 255, 255, 0.9);
	}	

}

.modalClose {
	visibility: hidden;

	&.open {
		visibility: visible;
		z-index: 2500;
		background: url('../images/icons/26x26px_close_mobile.svg');
		background-size: cover;
		border: none;
		width: 30px;
		height: 30px;	
		position: fixed;
		top: 20px;
		right: 17px;
		color: #fff;


	}
	
}