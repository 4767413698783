.c-icon {
	height: 50px;
	width: 50px;
	display: inline-block;
	background-size: cover;

	&--android {
		background: url('/images/android.svg');
	}

	&--apple {
		background: url('/images/apple.svg');
	}
	@media all and (max-width: $bp-sm) {
		height: 80px;
		width: 80px;
	}
}