.m-two-block {
	text-align: center;

	@media all and (max-width: $bp-sm) {
		text-align: left;
	}

	&--headline {
	    line-height: 120%;
	    margin: 30px auto;
	    max-width: 450px;

	    @media all and (max-width: $bp-sm) {
			margin: 30px 0 20px;
		}
	}
	&__image {
		width: 100%;
	}
	&--icons {
		margin-top: 20px;
		text-align: center;
	}
}