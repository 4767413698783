* {
	-webkit-font-smoothing: antialiased;
}

/*--- fonts ---*/
@mixin typo-regular() {
	font-family: BauerBodoni;
}
@mixin typo-sans() {
	font-family: FFMetaWebProBold;
}
@mixin typo-book() {
	font-family: FFMetaWebProBook;
}
@mixin typo-italic() {
	font-family: FFMetaWebProBookItalic;
}

/*--- headings ---*/
h1 {
	@include typo-sans;
	font-size: 48px;
}
h2 {
	@include typo-regular;
	font-size: 70px;

	@media all and (max-width: $bp-sm) {
		font-size: 38px;
		font-size: calc(77px/2);
	}
}
h3 {
	@include typo-sans;
	font-size: 48px;

	@media all and (max-width: $bp-sm) {
		// font-size: 24px;
		font-size: 36px;
	}
}
h4 {
	@include typo-sans;
	font-size: 30px;

	@media all and (max-width: $bp-sm) {
		font-size: 20px;
	}
}
h5 {
	@include typo-italic;
	font-size: 20px;
}
h6 {
	@include typo-book;
	font-size: 18px;
}
p {
	@include typo-book;
	font-size: 18px;
	margin-bottom: 30px;

	@media all and (max-width: $bp-sm) {
		font-size: 15px;
	}
}


/*--- special text ---*/
@mixin typo-menu() {
	@include typo-sans();
	font-size: 30px;
}

@mixin typo-quote() {
	font-family: FFMetaWebProBookItalic;
	font-size: 33px;
}
@mixin typo-intro() {
	@include typo-book;
	font-size: 20px;

	@media all and (max-width: $bp-sm) {
		font-size: 15px;
	}
}
@mixin typo-intro-italic() {
	font-family: FFMetaWebProBookItalic;
	font-size: 20px;

	@media all and (max-width: $bp-sm) {
		font-size: 15px;
	}
}
@mixin typo-subheader() {
	@include typo-sans;
	font-size: 20px;
}

@mixin typo-button() {
	@include typo-sans;
	font-size: 12px;
	text-transform: uppercase;

	@media all and (max-width: $bp-sm) {
		font-size: 15px;
	}
}

@mixin typo-big-headline() {
	@include typo-regular;
	font-size: 158px;

	@media all and (max-width: $bp-sm) {
		font-size: 102px;
	}
}
@mixin typo-sub-headline() {
	@include typo-sans;
	font-size: 18px;
	text-transform: uppercase;
}

.indention {
	margin-left: 40px;

	@media all and (max-width: $bp-sm) {
		margin-left: 20px;
	}

	&:before {
		content: "–";
	
	}
}