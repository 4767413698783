.o-transcription {
	display: none;
	background: inherit;

	.video-section & {
		position: relative;
		// top: -65px;
		top: -20px;
		margin-bottom: -125px;

		@media all and (max-width: $bp-sm) {
			top: 40px;
			margin-bottom: -65px;
		}	

	}

	@media all and (max-width: $bp-sm) {
		padding-top: 30px;
	}	
	&--white {
		background: #ffffff;

		@media all and (max-width: $bp-sm) {
			background: $light-grey;
		}
	}
	&--off-white {
		background: $off-white;
	}
	&--light-purple {
		background: $light-purple;
	}
	&--grey {
		background: $light-grey;
		color: $dark-grey;

		
	}

	&--mobile {
		display: block;
		background: inherit;

		@media all and (min-width: $bp-xs) {
			display: block !important;
		}
		@media all and (max-width: $bp-sm) {
			display: none;
		    /*padding: 30px;
		    margin: -30px -15px;*/
		    // padding-top: 45px;
		    // padding-top: 0;
		}
	}
}