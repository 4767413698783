
.video-section {
	
	.touchDevice & {
		padding-bottom: 100px;
		padding-top: 0;

		.o-transcription {
			padding-top: 0;
		}
	}
	@media all and (max-width: $bp-sm) {
		padding-bottom: 100px;
		padding-top: 0;

		.o-transcription {
			padding-top: 0;
		}
	}


	@media all and (max-width: $bp-xs) {
		padding-bottom: 100px;
	}
	header {
		// padding: 0 30px;
		h2 {
			padding-bottom: 15px !important;

		}
		img, h6 {
			opacity: 1;

		}
		
		@media all and (max-width: $bp-xs) {
			h6 {
				font-size: 16px;
			}
		}
		img {
			max-width: 100%;
			&.o-logotype {
				max-width: 80%;
			}
			// margin: 0 auto;
		}
	}	

	iframe.interview {
		opacity: 0;
		// position: absolute;
		// top: 0;
		border: 5px solid $dark-purple;
		width: 100%;
		.o-section--grey {
			border: none;
		}
		@media all and (max-width: $bp-sm) {
			max-height: 430px;
		}
		
	}
	img {
		max-width: 100%;

	}
}

.video-section.video-active {
	h2{
		opacity: 1;
		height: auto;
		transition: all 2s ease-in-out;
	}
	iframe {
		opacity: 1;
		transition: all 1s ease-in-out;
	}
	
	img.o-logotype, h6 {
		opacity: 0;
		transition: all 2s ease-in-out;
		height: 0;
	}
}

iframe.interview {
	z-index: 9999;
	position: relative;
	@media all and (max-width: $bp-sm) {
		height: 400px;
	}

	.in-viewport & {
		opacity: 1;
		transition: all 1s ease-in-out;
		@media all and (max-width: $bp-xs){
			opacity: 0;
		
		}
	}
}

.video-wrapper {
	position: relative;
	max-width: 900px;
	margin: 0 auto;
	z-index: 99;

	.touchDevice & {
		max-width: 708px;
		margin: 0 auto;
	}
	@media all and (max-width: $bp-sm) {
		// padding: 0 30px;
		max-width: 708px;
		margin: 0 auto;
		
	}
	@media all and (max-width: $bp-xs) {
		margin: 30px auto;
		
	}

	.video-poster {
		text-align: center;
		position: absolute;
		max-width: 900px;
		z-index: 999;
		overflow: hidden;

		// img {
		// 	opacity: 1;
		// }
		@media all and (max-width: $bp-xs) {
			position:relative;
		}
	}

	


	&:after {
		content:"";
		display: block;
		position: absolute;
		height: 290px;
		width: 2px;
		left: 50%;
		transform: translateX(-50%);
		bottom: -80px;
		border-right: 2px dashed $purple-line;
		z-index: -1;

		transition: all 400ms ease-in-out;

		// .video-active & {
		// 	bottom: -80px;
		// 	height: 145px;
		// }


		.touchDevice & {
			display:none;
		}
		@media all and (max-width: $bp-sm) {
			display:none;
		}
		@media all and (max-width: $bp-xs) {
			display:none;
		}
	}
	[data-video="1"] & {
		&:after {
			height: 290px;
			bottom: -187px;	
		}
	}	
}

[data-video="1"].video-active .video-wrapper:after {
	height: 290px;
	bottom: -80px;	
}

.o-video-mask {
	clip-path: circle(24% at 50% 45%);
	opacity: 1;
	transition: all 1s ease-in-out;

	@media all and (max-width: $bp-xs) {
		clip-path: circle(33% at 50% 50%);
		opacity: 1;
	}
  
	&--active, .in-viewport & {
		clip-path: circle(150% at 50% 50%);
		opacity: 0;
		@media all and (max-width: $bp-xs) {
			clip-path: circle(33% at 50% 50%);
			opacity: 1;
		}

	}
}

// Fix for browsers that dont support clip-path

.no-clip [data-video="1"] .video-poster {

	&:after {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: url('../images/assets/clip-bg-purple.png') no-repeat;
		background-size: 100% 100%;
		background-position: center center;
		transition: all 800ms ease-in-out;
		transform: scale(1.6);	
	}
}

.no-clip .video-active[data-video="1"] .video-poster {
	&:after {
		transform: scale(4);
		background-size: 100% 100%;
	}
}

.no-clip .o-section--grey .video-poster {
	&:after {
		content: "";
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background: url('../images/assets/clip-bg-grey.png') no-repeat;
		background-size: 100% 100%;
		background-position: center center;
		transition: all 800ms ease-in-out;
		transform: scale(1.6);	

	}
} 
.no-clip .o-section--grey.in-viewport .video-poster {
	&:after {
		background: url('../images/assets/clip-bg-grey.png') no-repeat;
		transform: scale(4);
		background-size: 100% 100%;

	}
} 

