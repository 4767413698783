footer {
	
	display: block;
	width: 100%;
	border-top: 2px solid $light-purple;
	overflow: auto;
	background: $purple;
	
	section { 
		background: $purple;
		width: 50%;
		float: left;
		border-left: 2px solid $light-purple;
		transition: background 400ms ease-in-out;

		@media all and (max-width: $bp-xs) {
			width: 100%;
			border-left: none;
			border-bottom: 2px solid $light-purple ;
		}

		&:hover {
			background: $light-purple;

		}
		
		a {
			display: block;
			padding: 55px 0 100px 60px;
			// font-family: $bold-font-family;
			color: $off-white-opacity;
			text-transform: uppercase;
			text-decoration: none; 

			@media all and (max-width: $bp-xs) {
				padding: 35px 0;
				text-align: center;
			}

		}
		&:first-child {
			border-left: none;
			text-align: right;
			a {
				padding-right: 60px;
				padding-left: 0;
				@media all and (max-width: $bp-xs) {
					padding-right: 0;
				}
			}
			
			
			
		}
	}
}