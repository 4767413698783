.o-headline {
	text-align: center;
	line-height: 1.13em;
	padding-bottom: 30px;
	word-break: break-word;
	max-width: 900px;
	margin: 0 auto;

	&--big {
		@include typo-big-headline;
		line-height: 100%;
	}
	&--sub {
		@include typo-sub-headline;
	}
	&--inlinesub {
		@include typo-subheader;	

		@media all and (max-width: $bp-sm) {
			font-size: 15px;
		}
	}
	&--top {
		margin-top: 30px;
	}
	&--long {
		font-size: 68px;
	}
}
