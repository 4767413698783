.c-chart {
/*	height: 320px;
	width: 400px;*/
	min-height: 450px;
	width: 90% !important;
	margin: 0 auto;



	@media all and (max-height: 768px) {
        min-height: 450px;
    }
	@media all and (max-width: $bp-sm) {
		height: 250px !important;

		#chart-stackedBar .highcharts-data-labels {
			display: none !important;
			opacity: 0!important;
		}
	}

	@media all and (min-width: $bp-sm) {
		&#chart-stackedBar, &#chart-bar {
			width: 70% !important;
		}
	}

	
	

    @media all and (max-width: $bp-xs) {
		height: 330px !important;
		min-height: initial;
    }

	t-span {
		text-shadow: none !important;
	}
	.highcharts-container {
		top: 30px;
	}
	.highcharts-legend-item {
		display: none;
	}
	.highcharts-grid {
		margin-bottom: -40px;
		path {
			opacity: 0;
			margin-bottom: -50px;
			transition:  opacity 5s ease-in-out;
			position: relative;
			bottom:0;
		}
	}
}
.highcharts-grid {
	path {
		opacity: 0;
		transition:  opacity 2s ease-in-out;
	}
}
.slick-active {
	.highcharts-grid {
		path {
			opacity: 0.8;
			/*margin-bottom: 0;*/
			transition:  opacity 2s ease-in-out !important;
		}
	}
}

#chart-stackedBar .highcharts-series-0 rect {
	fill: rgb(15, 182, 125);
}
.highcharts-title {
	@include typo-sans;
	font-size: 30px !important;
	
	@media all and (max-width: $bp-sm) {
		font-size: 20px !important;
	}
}
text {
	color: $black !important;
	text-shadow: none !important;
	@include typo-book;
}
#chart-area{
	max-width: 700px ;

}
