/* Font faces */
$base-font-family: 'MetaOt-Book', Helvetica, Arial;
$bold-font-family: 'MetaOT-Bold', Helvetica, Arial;
$bolditalic-font-family: 'MetaOT-BoldIta', Helvetica, Arial;
$bookitalic-font-family: 'MetaOT-BookIta', Helvetica, Arial;
$serif-font-family: 'BauerBodoni';


/* Descriptive variables (colors) */
$white: #fff;
$off-white: rgb(239,240,239);
$off-white-opacity: rgba(239,240,239, 0.8);
$black: rgb(51,49,50);

$clear-purple: rgb(114, 53,171);
$submenu-purple: rgb(139, 102, 180);
$purple: rgb(71,33,114);
$dark-purple: rgb(34,0,71);

$dark-grey: rgb(65,64,66);
$grey: rgb(126,125,126);

/* Hover states on dark background */
$light-grey: rgb(225,225,225);
$light-purple: rgb(88,47,135);

$purple-line : #b39acd;

/* Functional variables */
$text-color: $black;

/* Layout variables */
$base-gutter: 60px;
$base-gutter-md: 40px;
$base-gutter-sm: 30px;
$base-gutter-xs: 20px;

$grid-cols: 12;

$container-max-width: 1280px;

/* Break Points */
$bp-m:  1400px;
$bp-md: 1100px;
$bp-sm: 800px;
$bp-xs: 480px;