.c-button {
	padding: 12px 38px;
	border: none;
	border-radius: 10px;
	color: $off-white;
	@include typo-button;
	cursor: pointer;
	letter-spacing: 1px;
	display: block;
	outline: none;

	@media all and (max-width: $bp-sm) {	
		width: 50%;
	}
	@media all and (max-width: $bp-xs) {
		width: 90%;
		width: calc(100% - 40px);
		margin: 0 auto;
		padding: 20px;
		position: relative;
	}

	&--transcription {
		margin: 45px auto 0;
		position: relative;
		z-index: 999;

		.icon-book, .icon-close {
			padding-left: 10px;
			position: relative;
			top: 1px;
		}
		.icon-book {
			top: 2px;
			font-size: 15px;
		}
	}
	&--open {
		margin-bottom: 0;
	}
	&--purple {
		background: $light-purple;

		@media all and (min-width: $bp-sm) {
			&:hover{
			color: $black;
			background: $off-white;	
			}
		}

		&.c-button--open {
			// color: $off-white;
			color: $purple;
			// background: $off-white;
			// background: $light-purple;
			background: $white;
		}
	}
	&--black {
		background: $black;
	
		@media all and (max-width: $bp-sm) {
			&:hover {
				background: $purple;
			}
		}
		@media all and (max-width: $bp-xs) {
			&:hover {
				background: $black;
			}
		}

		&.c-button--open {
			background: $purple;
		}
	}
	&--open {
		background: $purple;
		.video-section[data-video='1'] & {
			background: $off-white;
			color: $black;
		}
	}
	
}

// Split button on mobile
.c-button-wrapper {
	position: relative;
	min-height: 80px;
	max-width: 400px;
	margin: 0 auto;
	top: 15px;
	padding: 0 30px;
	.en & {
		min-height: 90px !important;
	}

	&:after {
		content: "";
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-75%);
		width: 2px;
		display: block;
		height: 260px;
		border-right: 2px dashed $purple-line;
	}

	button {
		float:right;	
		width: 50%;
		height: 100%;
		padding: 45px 10px 20px 10px;
		border: none;
		border-radius: 10px;
		color: $off-white;
		min-height: 80px;
		@include typo-button;
		cursor: pointer;
		letter-spacing: 1px;
		display: inline-block;
		font-size: 12px !important;		
		border-top-right-radius: 20px;
		border-bottom-right-radius: 20px;
		border-bottom-left-radius: 0;
		border-top-left-radius: 0;
		position: relative;
		outline: none;
		.en & {
			min-height: 90px !important;
		}

		.icon-book, .icon-play, .icon-close {
			position: absolute;
			left: 50%;
			top: 16px;
			transform: translateX(-50%);
			font-size: 22px;
			padding-left:0;
		}
		.icon-close {
			font-size: 20px;
		}

		&:first-child {
			border-top-left-radius: 20px;
			border-bottom-left-radius: 20px;
			border-bottom-right-radius: 0;
			border-top-right-radius: 0;
			float: left;
		}
	}
}