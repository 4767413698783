.o-intro {
	padding: 0 60px;
	padding-bottom: 20px;
	text-align: center;
	
	@media all and (max-width: $bp-sm) {
		padding: 0;
	}

	&--regular {
		@include typo-intro;
	}
	
	&--italic {
		@include typo-intro-italic;
	}

	&--extrapadding {
		padding: 0 140px 30px; 
		text-align: center;
		@media all and (max-width: $bp-sm) {
			padding: 0;
		}
	}

	&--lighter {
		@include typo-intro-italic;
		font-size: 20px;
    	color: $grey;

    	@media all and (max-width: $bp-sm) {
			color: $black;
		}
	}
}    