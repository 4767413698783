

.preloader{
    position: fixed;
    top:0;
    left:0;
    bottom: 0;
    right: 0;
    background: $light-purple;
    z-index: 99999;
    img {
        opacity: 0;
        transition: opacity 300ms linear 300ms;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        &.visible {
            opacity: 1;
        }
        
    }
}


.preloader .preloader-gif{
    display: block;
    width: 120px;
    height: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin: -40px 0 0 -40px;
    // background: url('../images/assets/loader.gif') no-repeat;   
}




