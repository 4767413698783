.c-circle-slider {

	.slick-slide {
		outline: none;
	}

	&__content{
		width: 350px;
		height: 350px;
		margin: 0 auto;

		@media all and (max-width: $bp-sm) {
			width: 240px;
			height: 240px;
		}
		
		img {
			width: 100%;
			height: 100%;
			border-radius: 50%;
		}
	}
	@media all and (max-width: $bp-sm) {
		margin-top: 35px;
		margin-bottom: 0 !important;
	}
}