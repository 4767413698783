.o-video-quote {
	@include typo-quote;
    font-size: 33px;
    position: absolute;
    top: 45%;
    right: 0;
    display: inline-block;
    transform: translate(-50%, -50%);
    opacity: 1;
    z-index: 999;
    
    .video-active & {
    	opacity: 0;
    	transition: opacity 600ms ease-in-out;
    }

    @media all and (max-width: $bp-sm) {
		bottom: 0;
		top: initial;
	}
	@media all and (max-width: $bp-xs) {
		bottom: 0;
		top: initial;
		font-size: 18px;
		transform: translate(-10%, -50%);
	}

}
.o-quote {
	@include typo-quote;
	text-align: left;
	width: 500px;
	margin: 45px auto;
	padding: 0 25px;
	border-left: 10px solid;
	line-height: 1.2em;

	@media all and (max-width: $bp-sm) {
		display: none;
	}
}