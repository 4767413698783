/**
 * @license
 * MyFonts Webfont Build ID 2980686, 2015-02-26T10:06:30-0500
 * 
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are 
 * explicitly restricted from using the Licensed Webfonts(s).
 * 
 * You may obtain a valid license at the URLs below.
 * 
 * Webfont: FF Meta Web Pro Light by FontFont
 * URL: http://www.myfonts.com/fonts/fontfont/ff-meta/pro-light/
 * 
 * Webfont: FF Meta Web Pro Light Italic by FontFont
 * URL: http://www.myfonts.com/fonts/fontfont/ff-meta/pro-light-ita/
 * 
 * Webfont: FF Meta Web Pro Bold by FontFont
 * URL: http://www.myfonts.com/fonts/fontfont/ff-meta/pro-bold/
 * 
 * Webfont: FF Meta Web Pro Book Italic by FontFont
 * URL: http://www.myfonts.com/fonts/fontfont/ff-meta/pro-book-italic/
 * 
 * Webfont: FF Meta Web Pro Book by FontFont
 * URL: http://www.myfonts.com/fonts/fontfont/ff-meta/pro-book/
 * 
 * 
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=2980686
 * Licensed pageviews: 500,000
 * Webfonts copyright: 2009 Erik Spiekermann published by FSI FontShop International GmbH
 * 
 * © 2015 MyFonts Inc
*/


@font-face {
	font-family: 'FFMetaWebProLight';
	src: url('../fonts/meta/2D7B4E_0_0.eot');
	src: url('../fonts/meta/2D7B4E_0_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/meta/2D7B4E_0_0.woff2') format('woff2'),
		url('../fonts/meta/2D7B4E_0_0.woff') format('woff'),
		url('../fonts/meta/2D7B4E_0_0.ttf') format('truetype');
}
 
  
@font-face {
	font-family: 'FFMetaWebProLightItalic';
	src: url('../fonts/meta/2D7B4E_1_0.eot');
	src: url('../fonts/meta/2D7B4E_1_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/meta/2D7B4E_1_0.woff2') format('woff2'),
		url('../fonts/meta/2D7B4E_1_0.woff') format('woff'),
		url('../fonts/meta/2D7B4E_1_0.ttf') format('truetype');
}
 
  
@font-face {
	font-family: 'FFMetaWebProBold';
	src: url('../fonts/meta/2D7B4E_2_0.eot');
	src: url('../fonts/meta/2D7B4E_2_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/meta/2D7B4E_2_0.woff2') format('woff2'),
		url('../fonts/meta/2D7B4E_2_0.woff') format('woff'),
		url('../fonts/meta/2D7B4E_2_0.ttf') format('truetype');
}
 
  
@font-face {
	font-family: 'FFMetaWebProBookItalic';
	src: url('../fonts/meta/2D7B4E_3_0.eot');
	src: url('../fonts/meta/2D7B4E_3_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/meta/2D7B4E_3_0.woff2') format('woff2'),
		url('../fonts/meta/2D7B4E_3_0.woff') format('woff'),
		url('../fonts/meta/2D7B4E_3_0.ttf') format('truetype');
}

@font-face {
	font-family: 'FFMetaWebProBook';
	src: url('../fonts/meta/2D7B4E_4_0.eot');
	src: url('../fonts/meta/2D7B4E_4_0.eot?#iefix') format('embedded-opentype'),
		url('../fonts/meta/2D7B4E_4_0.woff2') format('woff2'),
		url('../fonts/meta/2D7B4E_4_0.woff') format('woff'),
		url('../fonts/meta/2D7B4E_4_0.ttf') format('truetype');
}

@font-face {
	font-family: 'BauerBodoni';
	src: url("../fonts/bauer/b015006tWeb.eot");
    src: url("../fonts/bauer/b015006tWeb.woff") format("woff");
}

@font-face {
	font-family: 'BauerBodoni';
    src: url("../fonts/bauer/b015006tWeb.eot");
	src: url("../fonts/bauer/b015006tWeb.woff") format("woff");
}

@font-face {
    font-family: 'icomoon';
    src:    url('../fonts/icomoon/icomoon.eot?u0xol');
    src:    url('../fonts/icomoon/icomoon.eot?u0xol#iefix') format('embedded-opentype'),
        url('../fonts/icomoon/icomoon.ttf?u0xol') format('truetype'),
        url('../fonts/icomoon/icomoon.woff?u0xol') format('woff'),
        url('../fonts/icomoon/icomoon.svg?u0xol#icomoon') format('svg');
    font-weight: normal;
    font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: 'icomoon' !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.icon-android:before {
    content: "\e900";
}
.icon-apple:before {
    content: "\e901";
}
.icon-arrow:before {
    content: "\e902";
}
.icon-book:before {
    content: "\e903";
}
.icon-close:before {
    content: "\e904";
}
.icon-download:before {
    content: "\e905";
    padding-left: 10px;
}
.icon-play:before {
    content: "\e906";
}

.icon-arrow_up:before {
    content: "\e907";
}
.icon-arrow_down:before {
    content: "\e908";
}

.icon-arrow_left:before {
    content: "\e909";
    padding-right: 15px;
    font-size: 13px;
}
.icon-arrow_right:before {
    content: "\e90a";
    padding-left: 15px;
    font-size: 13px;
}







