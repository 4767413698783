h1 {
	font-family: $bold-font-family;
}

h2 {
	font-family: $serif-font-family;
}

h3 {}

h4 {
	
}

h5 {

}

h6 {
	// font-family: $bold-font-family;
	// font-size: 18px;
}

p {
	/*font-family: 'MetaOT-Book';*/
}


a {
	@include typo-sans;
	color: $purple;
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}