.o-section {
	padding-bottom: 60px;
	overflow: hidden;
	
	

	@media all and (max-width: $bp-sm) {
		padding-bottom: 30px;
	}
	@media all and (max-width: $bp-xs) {
		&#feed, &#handel {
			min-height: 100vh;
		}
	}


	&__header {
		padding-top: 95px;
		padding-bottom: 50px;
		margin: 0;

		.o-headline {
			padding-bottom: 0px;
		} 

		@media all and (max-width: $bp-sm) {
			padding: 40px 0 30px;
		}

		&--tight {
			@media all and (max-width: $bp-sm) {
				padding-bottom: 0;
			}
		}
		
	}

	&--tight {
		padding-bottom: 0;

		@media all and (max-width: $bp-xs) {
			.video-section & {
				padding-bottom: 10px;
				padding-top: 30px;
			}
		}
		
	}

	&--inner {
		padding: 0 120px;
		max-width: 1440px;
		margin: 0 auto;

		@media all and (max-width: $bp-sm) {
			padding: 0 15px;
		}
	}

	// &--overflow {
	// 	overflow-x: hidden;
	// }

	&__inner {
		padding: 0 120px;
		max-width: 1440px;
		margin: 0 auto;
		@media all and (max-width: $bp-sm) {
			padding: 0 15px;
		}
		&--narrow {
			padding: 0 60px;
			@media all and (max-width: $bp-sm) {
				padding: 0 15px;
			}
		}
		&--scrollable {
			overflow-x: auto;
			overflow-y: hidden;
		}
	}



	&--grey {
		background: $light-grey;
		color: $black;
	}
	&--off-white {
		background: $off-white;
		color: $black;
	}
	&--purple {
		background: $purple;
		color: $off-white;

		a {
			color: $white;
			text-decoration: underline;
		}
	}
	&--no-margin {
		margin-top: 0;
	}
}

.anim {
	opacity: 0;
	transform: translateY(20%);
	transition: transform 600ms ease-in-out, opacity 300ms linear 150ms;

	&.c-results-table {
		@media all and (max-width: $bp-xs) {
			opacity: 1;
			transform: translateY(0);
		}
	}

	&.in-viewport {
		opacity: 1;
		transform: translateY(0);
	}

	.touchDevice & {
		opacity: 1;
		transform: translateY(0);
	}
}

		