.m-feed {

	&--is-visible {}

	&__content {
		max-width: 1440px;
		margin: 0 auto;
		@media all and (max-width: $bp-sm) {
			/*display: none;*/
		}
	}

	&--subheadline {
		color: $purple;
		text-align: center;

		@media all and (max-width: $bp-xs) {
			max-width: 195px;
    		margin: 20px auto 0;
		}
		    
	}

	&__half-content {
		width: 50%;
		display: inline-block;
		vertical-align: middle;
		margin-left: -4px;

		@media all and (max-width: $bp-sm) {
			width: 100%;
			display: block;
			margin: 0;
			padding: 0 15px;
		}

		h4 {
			line-height: 1.2em;
			margin-bottom: 20px;
		}

		img {
			max-width: 90%;
			border-radius: 50%;
			display: block;
			margin: 35px auto;

			@media all and (max-width: $bp-sm) {
				width: 165px;
				margin: 0 auto;
			}
		}
	}

	&--date {
		display: inline-block;
		border-bottom: 8px solid $white;
		margin-bottom: 14px;
		padding-bottom: 8px;

		@media all and (max-width: $bp-sm) {
			font-size: 20px;
			padding-bottom: 4px;
			border-bottom: 4px solid $white;
		}
	}
	
	&__article {
		@include clearfix;
		margin-top: 100px;
		opacity: 0;
		transform: translateY(50%);
		transition: transform 600ms ease-in-out, opacity 300ms linear 150ms;

		&.in-viewport {
			opacity: 1;
			transform: translateY(0);
		}
		.touchDevice & {
			opacity: 1;
			transform: translateY(0);
		}

		&:first-child {
			margin-top: 0;
		}
		@media all and (max-width: $bp-sm) {
			border-bottom: 2px dashed rgba(71, 33, 114, 0.38);
			margin-top: 30px;

			&:last-child {
				border-bottom: none;
			}
		}
	
	}
	
	//---Salvattore grid---//

	#grid[data-columns]::before {

		content: '2 .column.size-1of2';
	}
	@media all and (min-width: $bp-sm) {
		#grid[data-columns]::before {

			content: '2 .column.size-1of2';
		}
	}
	@include breakpoint(small) {
		#grid[data-columns]::before {
			content: '1 .column.size-1of1';
		}
	}

	.column { 
		float: left; 
		position: relative;
	}
	.size-1of2 { 
		width: 45%;
		margin: 0 2.5%; 
	}
	.size-1of1 {
		width: 100%;

	}		
	.column.size-1of2:first-child:after {
	    content: "";
	    position: absolute;
	    display: block;
	    top: 0;
	    right: -30px;
	    padding-bottom: 50px;
	    height: 100%;
	    border-right: 2px dashed #b39acd;
	}

	.size-1of2:nth-child(2) { 
		padding-top: 150px;
	}

	.column:nth-child(2) {

		.m-feed__half-content {
			float: right;

			&:after {
				clear: both;
			}
			@media all and (max-width: $bp-sm) {
				float: none;
			}
		}
	}
}