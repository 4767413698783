.c-transcription-wrapper {
	position: relative;

		.c-button--transcription {
		opacity: 0;
		transform: translateY(50%);
		transition: transform 600ms ease-in-out, opacity 300ms linear 150ms;
		pointer-events: none;

		&.fadein, .in-viewport & {
			opacity: 1;
			transform: translateY(0);
			pointer-events: auto;
		}
	}

	.transcription__content{
		padding: 100px 220px;
		max-width: 1440px;
		margin: 0 auto;
		
		@media all and (max-width: $bp-md) {
			padding: 100px 120px;
		}
		
		@media all and (max-width: $bp-sm) {
			padding: 30px 15px;
		}
	}
}


