.c-header {
	height: 65px;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 9999;
	background: $purple;
	border-bottom: 2px solid $light-purple;
	font-size: 18px;
	@media all and (max-width: $bp-xs) {
		font-size: 16px;
	}
	@include typo-sans;

	&__anchor {
		
		height: 100%;
		position: relative;
		// padding: 0 30px;
		display: inline-block;
		float: left;
		@media all and (max-width: $bp-xs) {
			padding: 0 15px;
		}
		&:first-child {
			border-right: 2px solid $light-purple;

			min-width: 263px;
			@media all and (max-width: $bp-xs) {
				width: 62%;
				min-width: initial;
				border-right: none;
			}
		}
		a {
			// @include vert-cent;
			padding: 20px 0 0 30px;
			height: 100%;
			display: block;
			color: $off-white-opacity;
			text-decoration: none;
			background: none;
			@media all and (max-width: $bp-xs) {
				padding: 20px 0 0 0;
			}
			&:hover {
				background: $light-purple;
				transition: background 400ms ease-in-out;
			}

		}
    	&--last {
    		float: right;
    		text-align: right;
    		a {
    			padding-right: 30px;
    		}
    	}
	}
	&__menu-holder {
		border-left: 2px solid $light-purple;
		display: inline-block;
		min-width:145px;
		position: relative;
		height: 100%;
		text-align: right;
		float: right;
		padding: 0 30px 0 15px;
		&:hover {
			cursor: pointer;
			background: $light-purple;
			transition: background 400ms ease-in-out;
			
		}
		@media all and (max-width: $bp-xs) {
			padding: 0 15px;
			width: 38%;
			min-width: initial;
		}
		.menu-wrapper {
			color: $off-white-opacity;
			@include vert-cent;

			&__label {
				position: absolute;
				left: 15px;
				opacity: 1;
				transition: opacity 400ms ease-in-out;
			}
			&__label-close {

			}
		}
	}

	
	
	&__burger {
		width: 16px;
		position: relative;
		height: 14px;
		display: inline-block;
		z-index: 1;
		margin-left: 10px;
		top: 2px;


		// &:before {
		// 	content: "Meny";
		// 	position: absolute;
		// 	top: 19px;
		// 	left: -35px;
		// 	color: #fff;
		// 	text-transform: uppercase;
		// }

	}


	&__burger {
		cursor: pointer;
		right: 0;
		top: 2px;
	}

	&__bun {
	    height: 2px;
	    width: 16px;
	    background-color: $white;
	    position: absolute;
	    z-index: 2;
	    left: 0;
		right: 0;
	    margin: 0 auto;
	    top: 40%;
	    transition: all 150ms ease-in-out 50ms;
	    transform-origin: 50% 50%;
	    backface-visibility: hidden;
	    opacity: 1;

		&--top {
			transform: translateY(-6px);

			.c-header--open & {
			    transform: rotate(45deg);
			}
		}

		&--middle {
			.c-header--open & {
			    opacity: 0;
			}			
		}

		&--bottom {
			transform: translateY(6px);

			.c-header--open & {
				transform: rotate(-45deg);
			}
		}
	}
}

.modal-open {
	body {
		height: 600px;
		height: 100vh;
		overflow: hidden !important;
		/*position: relative;*/
	}

}


/*-- SUBMENU --*/
.c-submenu {
	width: 100%;
    height: 70px;
    display: block;
    background: $light-purple;
    top: 65px;
    position: fixed;
    
    &--open {
    	display: none;
    	color: $white;
    	@include typo-sans;
    	font-size: 18px;
    	text-align: center;
    	transform: translateY(70%);
    	height: auto;

    	.icon {
    		position: absolute;
		    right: 30px;
		    font-size: 14px;
		    line-height: 1.8em;
    	}

    	@media all and (max-width: $bp-md) {
    		display: block;
    	}
    }

    &__wrapper {
    	display: table;
    	height: auto;
    	margin: 0 auto;
    	transform: translateY(40%);

    	@media all and (min-width: $bp-md) {
		    display: table !important;
		    height: auto !important;
    	}
    	@media all and (max-width: $bp-md) {

    		display: none;
    		transform: translateY(0);
    		margin: 0;
    		padding: 0;
    		top: 70px;
    		position: absolute;
    		overflow: auto;
    		background: rgba(0, 0, 0, 0.9);
    		
	    }

    	&-open {

			@media all and (max-width: $bp-md) {
	    		display: table;
	    		height: auto;
			}
	    	@media all and (max-width: $bp-md) {
	    		display: block;
	    		transform: translateY(0);
	    		margin: 0;
	    		padding: 0;
	    		top: 70px;
	    		position: absolute;
	    		overflow: auto;
	    		background: rgba(0, 0, 0, 0.9);
		    }
		}
    }
	.activeLink {
		border-bottom: 2px solid $white;

		@media all and (max-width: $bp-md) {
			border-bottom: none;

			li {
				display: inline-block;
				border-bottom: 2px solid $white;
			}
		}
	}
    &__link {
    	color: $white;
    	display: inline-block;
    	list-style: none;
    	margin: 5px 12px;
    	text-decoration: none;
    	@include typo-book;
    	font-size: 18px;
    	border-bottom: 2px solid transparent;
    	text-align: center;

    	
    	
    	@media all and (max-width: $bp-md) {
    		width: 100%;
    		border-bottom: none;
    		margin: 0;
    		padding: 20px;
   			background: $submenu-purple;

    		&:nth-child(odd) {
    			background: $purple-line;
    		}
    		
    		&:hover {
    			border-bottom: none;
    		}
    	}
    }

}
.sub {
	padding-top: 130px;
}




