.m-menu {
    top: 65px;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0,0,0,0.9);
    visibility: hidden;
    opacity: 0;
    transition: opacity 200ms linear 0ms, visibility 0ms linear 200ms;
    z-index: 1;

    .c-header--open & {
 	    visibility: visible;
 	    opacity: 1;
 	    transition: opacity 200ms linear 0ms, visibility 0ms linear 0ms;
    }

    @media all and (max-width: $bp-sm) {
    	overflow-y: auto;
    	position: absolute !important;
    	padding-bottom: 65px;
    	height: calc( 100vh - 65px ) !important;
    }

	&__wrapper {
		text-decoration: none;
		cursor: pointer;

		&:last-child {
			 @media all and (max-width: $bp-sm) {
		    	padding-bottom: 150px;
		    }
		}
	}
	&--filter {
		width: 100%;
	    height: 100%;
	    background: rgba(114, 53, 171, 0.5);
    	transition: all .8s ease; 
    	position: absolute;
    	top: 0;
    	left: 0;
	}	
	
	&--background {
		height: 100%;
    	width: 100%;
    	filter: grayscale(100%);
    	position: absolute;
    	top: 0;
    	left: 0;
    	transition: all .8s ease; 

    	&-white {
    		background: $light-grey;
    		filter: grayscale(0);	
    		
    		&:hover {
    			background: $purple;

    			.m-menu__title {
    				color: $white !important;
    			}
    		} 
    		.m-menu__title {
    			color: $purple !important;
    		}
    	}
    	
    	&-dark-purple {
    		background: $purple;
    		filter: grayscale(0);

    		&:hover {
    			background: $light-grey;
    			
    			.m-menu__title {
    				color: $purple !important;
    			}
    		} 
    		.m-menu__title {
    			color: $white !important;
    		}
       	}

    	&-purple {
    		background: $clear-purple;
    		filter: grayscale(0);

    		&:hover {
    			background: $light-grey;
    			
    			.m-menu__title {
    				color: $purple !important;
    			}
    		} 
    		.m-menu__title {
    			color: $white !important;
    		}
       	}
	}

	&--dark-hover:hover {
		.m-menu__title {
			color: $black !important;
		}
	}
	.m-menu__title:hover {
		.m-menu--dark-hover & {
			color: $black !important;
		}
	}

	&__title{
		padding: 0 30px;
		display: block;
	    position: absolute;
	    width: 100%;
	    text-align: center;
	    transform: translateY(50%);
	    bottom: 50%;
	    color: $white;
	    line-height: 1.2em;
	    text-decoration: none;

	    @media all and (max-width: $bp-md) {
	    	font-size: 25px;
	    }

	    @media all and (max-width: $bp-sm) {
	    	top: 0;
	    	padding: 0;
	    	font-size: 18px;
	    	color: $black;
	    }
	}
	
	&__caption {
		@media all and (max-width: $bp-m) {
			@include typo-book;
			font-size: 20px;
			display: block;
			line-height: 1.2em;
		}
		@media all and (max-width: $bp-sm) {
			@include typo-book;
			font-size: 15px;
			display: inline-block;
		}
	}

	.gridsizer {
		width: 25%;
	}

	.griditem {
		height: 15vh;
		width: 25%;

		&:hover {
			.m-menu--filter {
				background: none;
    			transition: all .8s ease; 
			}
			.m-menu--background {
				filter: grayscale(0);
    			transition: all .8s ease; 
			}	
    	}

    	@media all and (max-width:$bp-sm) {
			.m-menu__title {
				color: $black !important;
			}
	 		.m-menu--filter {
	 			background: none;
	 		}
	 		&:nth-child(even) .m-menu--background {
	 			background: $light-grey !important;
	 		}
	 		&:nth-child(odd) .m-menu--background {
	 			background: $off-white !important;
	 		}
	
			width: 100% !important;
			height: auto !important; 
			min-height: 50px !important; 
		}

		&--small {
			width: 25%;
		}
		&--medium {
			width: 30%;
		}
		&--big {
			width: 50%;
		}
		
		&--height {
			&-15{
				@include viewport-unit(height, 15vh);
			}
			&-20 {
				@include viewport-unit(height, 20vh);
			}
			&-25 {
				@include viewport-unit(height, 25vh);
			}
			&-30 {
				@include viewport-unit(height, 30vh);
			}
			&-35 {
				@include viewport-unit(height, 35vh);
			}
			&-40 {
				@include viewport-unit(height, 40vh);
			}
			&-45 {
				@include viewport-unit(height, 45vh);
			}
		}
	}

	/* backgrounds */
	#menu1 {
		background: url('/images/menu/vd.png') no-repeat center center;
		background-size: cover;
	}
	#menu2 {
		background: url('/images/menu/2015_Tidslinje.png') no-repeat center center;
		background-size: cover;
	}
	#menu3 {
		background: url('/images/menu/aktiekurs.png') no-repeat center center;
		background-size: cover;
	}
	#menu4 {
	}
	#menu5 {
	}
	#menu6 {
	}
	#menu7 {
	}
	#menu8 {
		background: url('/images/menu/finansieringslosningar_double.png') no-repeat center center;
		background-size: cover;
	}
	#menu9 {
		background: url('/images/menu/developer.png') no-repeat bottom center;
		background-size: cover;
	}
	#menu10 {
		background: url('/images/menu/overigaprod_double.png') no-repeat center center;
		background-size: cover;
	}
	#menu11 {
		background: url('/images/menu/Styrelse_ledning_double.png') no-repeat center center;
		background-size: cover;
	}
	#menu12 {
	}
}