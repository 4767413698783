.o-article {

	&__content {
		padding-left: 140px;
		padding-right: 140px;

		@media all and (max-width: $bp-md) {
			padding: 0;
		}
		@media all and (max-width: $bp-sm) {
			padding: 0;
		}
	}
	// &--intro-italic {
	// 	@include typo-intro-italic;
	// 	text-align: center;
	// 	padding: 0 220px;
		
	// 	@media all and (max-width: $bp-sm) {
	// 		padding: 0;
	// 	}
	// }
	// &--intro {
	// 	@include typo-intro;
	// 	text-align: center;
	// 	padding: 0 220px;
		
	// 	@media all and (max-width: $bp-md) {
	// 		padding: 0;
	// 	}
	// 	@media all and (max-width: $bp-sm) {
	// 		padding: 0;
	// 	}
	// }
	&__image {
		max-width: 100%;
		display: block;
		margin: 0 auto;
		width: 100%;
		margin-bottom: 60px;

		&-spacing {
			margin-top: 55px;
		}

		@media all and (max-width: $bp-sm) {
			margin: 30px 0;
		}

		/*.o-article & {
			padding: 0;
		}*/
	}
}