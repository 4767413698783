.c-person {

	text-align: center;
	padding: 0 10px;
	color: $dark-grey;
	max-width: 1440px;
	margin: 0 auto;

	&__wrapper {
		@media all and (max-width: $bp-sm) {
			margin-bottom: 20px;
		}
	}
	&__image {
		width: 100%;
		height: auto;
		border-radius: 50%;
		margin: 30px 0;

		@media all and (max-width: $bp-sm) {
			margin: 10px 0;
		}
	}
	&__role {
		margin-bottom: 0;

		@media all and (max-width: $bp-sm) {
			@include typo-sans;	
			font-size: 13px;
		}
		
	}
	&__name {
		line-height: 1.2em;
		
		@media all and (max-width: $bp-sm) {
			@include typo-book;	
			font-size: 18px;
			height: 35px;
		}
		
		&:after {
			content: "";
			width: 90px;
			border-bottom: 8px solid $white;
			display: block;
			margin: 10px auto 10px;

			@media all and (max-width: $bp-sm) {
				display: none;
			}
		}
	}

	
}