.text-left {
	text-align: left;
}
.text-center {
	text-align: center;
}
.text-right {
	text-align: right;
}
.inline {
	display: inline-block;
}

.hidden {
	opacity: 0 !important;
	height: 0;
}

.mobile-only {
	display: none;

	@media all and (max-width: $bp-xs) {
		display: block;
	}
}
.tablet-only {
	display: none;

	.touchDevice & {
		display: block;
	}
	@media all and (max-width: $bp-sm) {
		display: block;

		
	}
}

.hidden-xs {
	@media all and (max-width: $bp-xs) {
		display: none;
	}
}
.hidden-sm {
	@media all and (max-width: $bp-sm) {
		display: none;
	}
}
.hidden-md {
	@media all and (max-width: $bp-md) {
		display: none;
	}
}


span[class*="icon-"] {
	padding-left: 10px;
    position: relative;
    top: 1px;
}