.c-content-block {
	margin-bottom: 90px;
	
	@media all and (max-width: $bp-sm) {
		margin-bottom: 0;
		#app & {
			&:first-child {
				margin-bottom: 30px;
			}
		}
	}

	&__image{
		width: 100%;
		height: auto;
		display: block;
		margin: 0 auto;
	}
	&__video {
		width: 100%;
		min-height: 250px;
		height: auto;
		
		@media all and (max-width: $bp-xs) {
			min-height: 150px;	
		}
	}
}