.c-results-table {
	margin: 30px 0 40px;
	border-collapse: collapse;
	width: 100%;
	@include typo-book();
	
	thead, .table-header {
		font-size: 13px;
		border-bottom: 2px solid #222;
		@media all and (max-width: $bp-xs) {
			font-size: 11px !important;
		}
	}
	

	@media all and (max-width: 1080px) {
		width: 1000px;

	}
	@media all and (max-width: $bp-xs) {
		margin: 0 0 20px;
		font-size: 11px !important;

	}


	// -webkit-box-sizing:border-box;
	// 	-moz-box-sizing:border-box;
	// 		-ms-box-sizing:border-box;
	// 			box-sizing:border-box;

	tr {
		@media all and (max-width: $bp-xs) {
			font-size: 11px !important;
		}
		td:first-of-type {
			max-width: 275px;
			
			@media all and (max-width: $bp-xs) {
				font-size: 11px !important;
				@include typo-book();
			}

		}
		
		&.border {
			// background:url('../images/assets/border.gif') top left repeat-x;
			td {
				background:url('../images/assets/border.gif') top left repeat-x;
				&.filled {
					background-color: #e7e8e7;
				}
			}

		}

	}
	td,th {
		padding: 5px 10px;
		vertical-align: bottom;
		min-width: 10%;
		position: relative;
		text-size-adjust: 100%;
		@media all and (max-width: $bp-xs) {
			font-size: 11px !important;
		}
		


		&.filled {
			background-color: #e7e8e7;
		}
		&.endresult {
			&:after {
				content: "";
				background: #000;
				height: 2px;
				width: 100%;
				position: absolute;
				left: 0;
				top: 0;
			}
		}
	}

	

}